import { ToastMessagesTypes } from "./toastMessages.types";

const INITIAL_STATE = {
  toastMessages: [],
};

const toastMessagesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ToastMessagesTypes.SET_TOAST_MESSAGES:
      return {
        ...state,
        toastMessages: [...state.toastMessages, action.payload],
      };
    case ToastMessagesTypes.POP_TOAST_MESSAGES:
      const [, ...restToastMessages] = state.toastMessages;
      return {
        ...state,
        toastMessages: restToastMessages,
      };
    default:
      return state;
  }
};

export default toastMessagesReducer;
