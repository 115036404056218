import {
  ADMINISTRATION_PAGINATION,
  MANIFESTS_PAGINATION,
  SHIPMENTS_PAGINATION,
  TAGS_PAGINATION,
  CLIENTS_PAGINATION,
  SERVICE_REQUESTS_PAGINATION,
} from "../../constants";

import { FilterTypes } from "./filters.types";

const INITIAL_STATE = {
  pickupDate: {
    startDate: null,
    endDate: null,
  },
  deliveryDate: {
    startDate: null,
    endDate: null,
  },
  actualDeliveryDate: {
    startDate: null,
    endDate: null,
  },
  actualPickupDate: {
    startDate: null,
    endDate: null,
  },
  scheduledAttemptDate: {
    startDate: null,
    endDate: null,
  },
  expectedAttemptDate: {
    startDate: null,
    endDate: null,
  },
  createdDate: {
    startDate: null,
    endDate: null,
  },
  shipmentStatuses: [],
  waybillSearchTerm: "",
  userSearchTerm: "",
  client: null,
  shipmentsPagination: {
    page: SHIPMENTS_PAGINATION.START_INDEX,
    rowsPerPage: SHIPMENTS_PAGINATION.PAGE_SIZES[1],
  },
  shipmentTypes: [],

  manifestsPagination: {
    page: MANIFESTS_PAGINATION.START_INDEX,
    rowsPerPage: MANIFESTS_PAGINATION.PAGE_SIZES[0],
  },
  administrationPagination: {
    page: ADMINISTRATION_PAGINATION.START_INDEX,
    rowsPerPage: ADMINISTRATION_PAGINATION.PAGE_SIZES[0],
  },
  tagsPagination: {
    page: TAGS_PAGINATION.START_INDEX,
    rowsPerPage: TAGS_PAGINATION.PAGE_SIZES[0],
  },
  clientsPagination: {
    page: CLIENTS_PAGINATION.START_INDEX,
    rowsPerPage: CLIENTS_PAGINATION.PAGE_SIZES[0],
  },
  serviceRequestsPagination: {
    page: SERVICE_REQUESTS_PAGINATION.START_INDEX,
    rowsPerPage: SERVICE_REQUESTS_PAGINATION.PAGE_SIZES[0],
  },
  tags: [],
  shipmentsCurrentTab: "allshipments",
};

const filtersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FilterTypes.UPDATE_CLIENT:
      return {
        ...state,
        client: action.payload.client,
      };

    case FilterTypes.UPDATE_SHIPMENTS_PAGINATION:
      return {
        ...state,
        shipmentsPagination: {
          ...state.shipmentsPagination,
          ...action.payload,
        },
      };

    case FilterTypes.UPDATE_MANIFESTS_PAGINATION:
      return {
        ...state,
        manifestsPagination: {
          ...state.manifestsPagination,
          ...action.payload,
        },
      };

    case FilterTypes.UPDATE_SERVICE_REQUESTS_PAGINATION:
      return {
        ...state,
        serviceRequestsPagination: {
          ...state.serviceRequestsPagination,
          ...action.payload,
        },
      };

    case FilterTypes.UPDATE_ADMINISTRATION_PAGINATION:
      return {
        ...state,
        administrationPagination: {
          ...state.administrationPagination,
          ...action.payload,
        },
      };

    case FilterTypes.UPDATE_TAGS_PAGINATION:
      return {
        ...state,
        tagsPagination: {
          ...state.tagsPagination,
          ...action.payload,
        },
      };

    case FilterTypes.UPDATE_CLIENTS_PAGINATION:
      return {
        ...state,
        clientsPagination: {
          ...state.clientsPagination,
          ...action.payload,
        },
      };

    case FilterTypes.UPDATE_SHIPMENTS_CURRENT_TAB:
      return {
        ...state,
        shipmentsCurrentTab: action.payload.shipmentsCurrentTab,
      };

    case FilterTypes.SET_WAYBILL_TERM:
      return {
        ...state,
        waybillSearchTerm: action.payload,
      };

    case FilterTypes.SET_USER_TERM:
      return {
        ...state,
        userSearchTerm: action.payload,
      };

    case FilterTypes.SET_SHIPMENT_STATUSES:
      return {
        ...state,
        shipmentStatuses: action.payload,
      };
    case FilterTypes.SET_SHIPMENT_TYPES:
      return {
        ...state,
        shipmentTypes: action.payload,
      };

    case FilterTypes.SET_PICKUP_DATE:
      return {
        ...state,
        pickupDate: action.payload,
      };

    case FilterTypes.SET_DELIVERY_DATE:
      return {
        ...state,
        deliveryDate: action.payload,
      };

    case FilterTypes.SET_ACTUAL_DELIVERY_DATE:
      return {
        ...state,
        actualDeliveryDate: action.payload,
      };

    case FilterTypes.SET_ACTUAL_PICKUP_DATE:
      return {
        ...state,
        actualPickupDate: action.payload,
      };

    case FilterTypes.SET_SCHEDULED_ATTEMPT_DATE:
      return {
        ...state,
        scheduledAttemptDate: action.payload,
      };

    case FilterTypes.SET_EXPECTED_ATTEMPT_DATE:
      return {
        ...state,
        expectedAttemptDate: action.payload,
      };

    case FilterTypes.SET_CREATED_DATE:
      return {
        ...state,
        createdDate: action.payload,
      };
    case FilterTypes.SET_TAGS:
      return {
        ...state,
        tags: action.payload,
      };

    default:
      return state;
  }
};

export default filtersReducer;
