import { baseURL, getIdToken } from "./index";

// Axios
import axios from "axios";

/**
 *
 * @param {string} waybillNumber
 * @param {number} pageSize
 * @param {number} pageNumber
 * @returns
 */

export const getStatusChangesOfShipment = async ({ waybillNumber }) => {
  return axios({
    baseURL,
    url: `/shipments/${waybillNumber}/statuses`,
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
  }).then(response => response.data);
};
