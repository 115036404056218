import { baseURL, getIdToken } from "./index";

import axios from "axios";

/**
 * Get the list of clients the current user has access to
//  * @param {string} search
 */
export const getClients = async ({ search, pageSize, pageNumber }) =>
  axios({
    baseURL,
    url: `/clients/`,
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
    params: {
      search,
      pageSize,
      pageNumber,
    },
  }).then(response => response.data);

export const addClients = async userData => {
  axios({
    baseURL,
    url: `/clients/`,
    method: "POST",
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
    data: userData,
  })
    .then(response => response.data)
    .catch(error => error);
};
