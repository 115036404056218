import { DraftManifestTypes } from "./draftManifest.types";

const INITIAL_STATE = {
  waybillNumbers: [],
  clientId: null,
};

const accessorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DraftManifestTypes.ADD_WAYBILLNUMBER_TO_DRAFTMANIFEST:
      return {
        ...state,
        waybillNumbers: [...state.waybillNumbers, action.payload].filter(
          (item, index, inputArray) => {
            return inputArray.indexOf(item) === index;
          },
        ),
      };

    case DraftManifestTypes.ADD_WAYBILLNUMBERS_TO_DRAFTMANIFEST:
      return {
        ...state,
        waybillNumbers: [...state.waybillNumbers, ...action.payload].filter(
          (item, index, inputArray) => {
            return inputArray.indexOf(item) === index;
          },
        ),
      };

    case DraftManifestTypes.REMOVE_FROM_DRAFTMANIFEST:
      return {
        ...state,
        waybillNumbers: state.waybillNumbers.filter(
          waybillNumber => waybillNumber !== action.payload,
        ),
      };

    case DraftManifestTypes.CLEAR_DRAFTMANIFEST:
      return {
        ...state,
        waybillNumbers: [],
        clientId: null,
      };

    case DraftManifestTypes.SET_DRAFT_MANIFEST_CLIENT_ID:
      return {
        ...state,
        clientId: action.payload.clientId,
      };
    default:
      return state;
  }
};

export default accessorReducer;
