import { ToastMessagesTypes } from "./toastMessages.types";

export const addSuccessToastMessages = message => ({
  type: ToastMessagesTypes.SET_TOAST_MESSAGES,
  payload: {
    type: "success",
    message,
  },
});

export const addFailureToastMessages = message => ({
  type: ToastMessagesTypes.SET_TOAST_MESSAGES,
  payload: {
    type: "failure",
    message,
  },
});

export const addInfoToastMessages = message => ({
  type: ToastMessagesTypes.SET_TOAST_MESSAGES,
  payload: {
    type: "info",
    message,
  },
});

export const popFirstToastMessage = () => ({
  type: ToastMessagesTypes.POP_TOAST_MESSAGES,
});
