import { baseURL, getIdToken } from "./index";

// Axios
import axios from "axios";

/**
 *
 * @param {string[]} waybills
 * @returns {{status: string, manifestId: number}}
 */
export const createManifest = async waybills =>
  axios({
    baseURL,
    url: "/manifests",
    method: "POST",
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
    data: { waybills },
  }).then(response => response.data);

/**
 *
 * @returns {string[]}
 */
export const getManifests = async ({
  pageSize = 25,
  pageNumber = 0,
  clientUid = undefined,
}) =>
  axios({
    baseURL,
    url: "/manifests",
    params: {
      pageSize,
      pageNumber,
      clientUid,
    },
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
  }).then(response => response.data);

export const getManifest = async manifestId =>
  axios({
    baseURL,
    url: `/manifests/${manifestId}`,
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
  }).then(response => response.data);
