import { getAuth } from "firebase/auth";

const { REACT_APP_SERVER_URL } = process.env;

export const baseURL = `${REACT_APP_SERVER_URL}/api/v1`;

export const getIdToken = async () => await getAuth().currentUser.getIdToken();

export * from "./shipments";
export * from "./manifests";
export * from "./boxes";
export * from "./users";
export * from "./clients";
export * from "./reports";
export * from "./comments";
export * from "./apikeys";
export * from "./files";
export * from "./statusChanges";
export * from "./buyerAttempts";
export * from "./tags";
export * from "./serviceRequests";
