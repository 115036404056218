import { baseURL, getIdToken } from "./index";

import axios from "axios";

// API

export const getAllServiceRequests = async (pageSize, pageNumber) =>
  axios({
    baseURL,
    url: `/service-requests/`,
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
    params: {
      pageSize,
      pageNumber,
    },
  })
    .then(response => response.data)
    .catch(error => error);
