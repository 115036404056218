import { baseURL, getIdToken } from "./index";

import axios from "axios";

export const getApiKeys = async (pageSize, pageNumber, cliendId) =>
  axios({
    baseURL,
    url: `/api-keys`,
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
    params: {
      pageSize,
      pageNumber,
      cliendId,
    },
  })
    .then(response => response.data)
    .catch(error => error);

export const addApiKeys = async userData => {
  return axios({
    baseURL,
    url: "/api-keys",
    method: "POST",
    headers: {
      Authorization: `bearer ${await getIdToken()} `,
    },
    data: userData,
  })
    .then(response => response.data)
    .catch(error => error);
};

export const deleteApiKeys = async apiClientId => {
  axios({
    baseURL,
    url: `/api-keys/${apiClientId}`,
    method: "DELETE",
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
  })
    .then(response => response.json())
    .then(data => console.log(data))
    .catch(error => console.error(error));
};
