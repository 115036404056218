import { FilterTypes } from "./filters.types";

export const updateClient = client => ({
  type: FilterTypes.UPDATE_CLIENT,
  payload: {
    client,
  },
});

export const updateShipmentsPagination = payload => ({
  type: FilterTypes.UPDATE_SHIPMENTS_PAGINATION,
  payload,
});

export const updateManifestsPagination = payload => ({
  type: FilterTypes.UPDATE_MANIFESTS_PAGINATION,
  payload,
});

export const updateAdministrationPagination = payload => ({
  type: FilterTypes.UPDATE_ADMINISTRATION_PAGINATION,
  payload,
});

export const updateTagsPagination = payload => ({
  type: FilterTypes.UPDATE_TAGS_PAGINATION,
  payload,
});

export const updateClientsPagination = payload => ({
  type: FilterTypes.UPDATE_CLIENTS_PAGINATION,
  payload,
});

export const updateServiceRequestsPagination = payload => ({
  type: FilterTypes.UPDATE_SERVICE_REQUESTS_PAGINATION,
  payload,
});

export const updateShipmentsCurrentTab = payload => ({
  type: FilterTypes.UPDATE_SHIPMENTS_CURRENT_TAB,
  payload,
});

export const setWaybillSearchTerm = payload => ({
  type: FilterTypes.SET_WAYBILL_TERM,
  payload,
});

export const setUserSearchTerm = payload => ({
  type: FilterTypes.SET_USER_TERM,
  payload,
});

export const setShipmentStatuses = payload => ({
  type: FilterTypes.SET_SHIPMENT_STATUSES,
  payload,
});

export const setShipmentTypes = payload => ({
  type: FilterTypes.SET_SHIPMENT_TYPES,
  payload,
});

export const setPickupDate = payload => ({
  type: FilterTypes.SET_PICKUP_DATE,
  payload,
});

export const setDeliveryDate = payload => ({
  type: FilterTypes.SET_DELIVERY_DATE,
  payload,
});

export const setCreatedDate = payload => ({
  type: FilterTypes.SET_CREATED_DATE,
  payload,
});

export const setActualDeliveryDate = payload => ({
  type: FilterTypes.SET_ACTUAL_DELIVERY_DATE,
  payload,
});

export const setActualPickupDate = payload => ({
  type: FilterTypes.SET_ACTUAL_PICKUP_DATE,
  payload,
});

export const setScheduledAttemptDate = payload => ({
  type: FilterTypes.SET_SCHEDULED_ATTEMPT_DATE,
  payload,
});

export const setExpectedAttemptDate = payload => ({
  type: FilterTypes.SET_EXPECTED_ATTEMPT_DATE,
  payload,
});

export const setTags = payload => ({
  type: FilterTypes.SET_TAGS,
  payload,
});
