import { baseURL, getIdToken } from "./index";

// Axios
import axios from "axios";

/**
 *
 * @param {number} label Box's label number
 * @returns object
 */

export const getBoxDetails = async label => {
  return axios({
    baseURL,
    url: `/boxes/${label}`,
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
  }).then(response => response.data);
};

/**
 *
 *
 *
 * @param {string} label
 */

export const getBoxByLabel = label => ({
  box: {
    boxId: 1,
    boxLabel: "343889GHK-001",
    scanCode: "343889GHK-001",
  },
});

/**
 *
 * @param {string} label
 */

export const getItemsOfBoxByLabel = async label => {
  return axios({
    baseURL,
    url: `/boxes/${label}/items`,
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
  }).then(response => response.data);
};
