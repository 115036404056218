import React from "react";

const HamburgerIcon = ({ onToggle, isOpen }) => {
  return (
    <div
      onClick={onToggle}
      onKeyDown={onToggle}
      role="button"
      aria-label="Show pages"
      tabIndex={0}
      className={` nav-icon1 w-5 h-5 relative ${
        isOpen ? "open" : ""
      } duration-500 cursor-pointer mb-2.5 `}>
      <span
        className={`top-2 left-0 opacity-100 rounded-lg bg-black w-full h-0.5 absolute block duration-500 ${
          isOpen ? ` rotate-135 top-4` : ``
        }`}></span>
      <span
        className={`top-4 left-0 opacity-100 rounded-lg bg-black w-full h-0.5 absolute block duration-500 ${
          isOpen ? ` scale-0 opacity-0 ` : ``
        }`}></span>
      <span
        className={` left-0 opacity-100 rounded-lg bg-black w-full h-0.5 absolute block duration-500 ${
          isOpen ? `top-4 rotate-[-135deg] ` : `top-6`
        }`}></span>
    </div>
  );
};

export default HamburgerIcon;
