import React, { useEffect, useState } from "react";

import { ReactComponent as CloseIcon } from "../../assets/Close.svg";
import { useDispatch } from "react-redux";
import { popFirstToastMessage } from "../../redux/toastMessages/toastMessages.actions";
const Toast = ({ message, type }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);

  const closeToast = () => {
    setIsOpen(false);
  };

  const openToast = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    openToast();
    const interval = setTimeout(() => {
      closeToast();
      dispatch(popFirstToastMessage());
    }, 5000);
    return () => clearTimeout(interval);
  }, []);

  return (
    isOpen && (
      <div className="flex flex-row justify-end">
        <div
          className={`rounded-md shadow-toast-box ${
            type === "success"
              ? "bg-primary-light text-grey-900"
              : type === "failure"
              ? "bg-danger-main text-white"
              : type === "info"
              ? "bg-success-light text-black"
              : ""
          } p-4 z-50 flex flex-row justify-center`}>
          <span className={`mr-2 text-xs font-medium`}>{message}</span>
          <span className="pl-3">
            <div className="-mx-1.5 -my-1.5">
              <CloseIcon
                onClick={closeToast}
                className="h-6 w-6 fill-current cursor-pointer"
                aria-hidden="true"
              />
            </div>
          </span>
        </div>
      </div>
    )
  );
};

export default Toast;
