import { baseURL, getIdToken } from "./index";

import { DateTime } from "luxon";
import axios from "axios";

export const reportShipmentsCreated = async ({
  statuses = [],
  shipmentTypes = [],
  clientUid,
  pickupDateGtEq,
  pickupDateLtEq,
  deliveryDateGtEq,
  deliveryDateLtEq,
  createdAtGtEq = DateTime.now().minus({ days: 28 }).toFormat("yyyy-MM-dd"),
  createdAtLtEq = DateTime.now().toFormat("yyyy-MM-dd"),
  actualDeliveryDateGtEq,
  actualDeliveryDateLtEq,
  actualPickupDateGtEq,
  actualPickupDateLtEq,
  expectedAttemptDateGtEq,
  expectedAttemptDateLtEq,
  scheduledAttemptDateGtEq,
  scheduledAttemptDateLtEq,
  search,
  tags,
  dateDimension = "week",
  dateField = "created",
}) => {
  return axios({
    baseURL,
    url: "/reports/created",
    method: "POST",
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
    data: {
      search,
      clientUid,
      statuses,
      shipmentTypes,
      pickupDateGtEq,
      pickupDateLtEq,
      deliveryDateGtEq,
      deliveryDateLtEq,
      createdAtGtEq,
      createdAtLtEq,
      actualDeliveryDateGtEq,
      actualDeliveryDateLtEq,
      actualPickupDateGtEq,
      actualPickupDateLtEq,
      expectedAttemptDateGtEq,
      expectedAttemptDateLtEq,
      scheduledAttemptDateGtEq,
      scheduledAttemptDateLtEq,
      tags,
      dateDimension,
      dateField,
    },
  }).then(response => response.data);
};

export const getDailyDashboardData = async ({ clientUid }) =>
  axios({
    baseURL,
    url: "/reports/daily-dashboard-stats",
    method: "POST",
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
    data: { clientUid },
  })
    .then(response => response.data)
    .catch(error => error);
