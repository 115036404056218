import React from "react";
import SideNavList from "../SideNavList";

const SideNav = props => {
  return (
    <div className="flex flex-row main-container mt-3">
      <div className="lg:flex lg:flex-col hidden sidebar px-0 lg:w-2/12">
        <SideNavList />
      </div>
      <div className="flex flex-col px-0 w-full lg:w-10/12">
        <div className="bg-grey-50 p-3 lg:p-7 rounded-2xl">
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default SideNav;
