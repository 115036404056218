export const SHIPMENTS_PAGINATION = {
  START_INDEX: 0,
  PAGE_SIZES: [10, 25, 50],
};

export const MANIFESTS_PAGINATION = {
  START_INDEX: 0,
  PAGE_SIZES: [10, 25],
};

export const ADMINISTRATION_PAGINATION = {
  START_INDEX: 0,
  PAGE_SIZES: [10, 25],
};

export const TAGS_PAGINATION = {
  START_INDEX: 0,
  PAGE_SIZES: [10, 25],
};

export const CLIENTS_PAGINATION = {
  START_INDEX: 0,
  PAGE_SIZES: [10, 25],
};

export const SERVICE_REQUESTS_PAGINATION = {
  START_INDEX: 0,
  PAGE_SIZES: [10, 25],
};

export const LOGIN_PAGE_TABS = {
  RESET_PASSWORD: "Reset Password",
  LOGIN: "Login",
};
