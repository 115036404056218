const pages = [
  {
    path: "",
    groups: ["Developer Administrators", "admin", "Super user", "user"],
  },
  {
    path: "shipments",
    groups: ["Developer Administrators", "admin", "Super user", "user"],
  },
  {
    path: "shipment",
    groups: ["Developer Administrators", "admin", "Super user", "user"],
  },
  {
    path: "search",
    groups: ["Developer Administrators", "admin", "Super user", "user"],
  },
  {
    path: "manifests",
    groups: ["Developer Administrators", "admin", "Super user", "user"],
  },
  {
    path: "manifest",
    groups: ["Developer Administrators", "admin", "Super user", "user"],
  },
  {
    path: "ndr",
    groups: ["Developer Administrators", "admin", "Super user", "user"],
  },
  {
    path: "cod",
    groups: ["Developer Administrators", "admin", "Super user", "user"],
  },
  {
    path: "reports",
    groups: ["Developer Administrators", "admin", "Super user", "user"],
  },
  {
    path: "dev-admin",
    groups: ["Developer Administrators"],
  },
  {
    path: "admin",
    groups: ["Developer Administrators", "admin", "Super user"],
  },
  {
    path: "service-requests",
    groups: ["Developer Administrators", "admin", "Super user", "user"],
  },
  {
    path: "shipments/upload",
    groups: ["Developer Administrators", "admin", "Super user"],
  },
];

export default pages;
