import { Disclosure, Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import ClientSelector from "../ClientSelector";
import { Engagespot } from "@engagespot/react-component";
import HamburgerIcon from "./hamberger";
import { ReactComponent as NotificationIcon } from "../../assets/Notification.svg";
import SideNavList from "../SideNavList";
import { getAuth } from "firebase/auth";
import logo from "../../assets/logo.svg";
import { updateAccessor } from "../../redux/accessor/accessor.actions";
import { useNavigate } from "react-router";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function HeaderNew() {
  const dispatch = useDispatch();
  const auth = getAuth();
  const accessor = useSelector(state => state.accessor.accessor);
  const navigate = useNavigate();

  const signOutUser = () => {
    auth.signOut();
    dispatch(updateAccessor(null));
  };

  const navigateToDevAdminPage = () => {
    navigate("/dev-admin");
  };

  const isDevAdmin = accessor?.groups?.some(
    group => group.groupName === "Developer Administrators",
  );

  const randomColors = [
    "bg-grey-700",
    "bg-orange-900",
    "bg-red-900",
    "bg-yellow-900",
    "bg-green-900",
    "bg-slate-900",
  ];
  const randomColor =
    randomColors[Math.floor(Math.random() * randomColors.length)];

  return (
    <Disclosure as='nav' className='bg-white shadow'>
      {({ open }) => (
        <>
          <div className='mx-auto px-4 sm:px-6 lg:px-8'>
            <div className='flex justify-between h-16'>
              <div className='flex'>
                <div className='flex justify-start items-center space-x-6'>
                  <div className='-mr-2 flex items-center lg:hidden'>
                    <Disclosure.Button className='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-main'>
                      <span className='sr-only'>Open main menu</span>
                      <HamburgerIcon isOpen={open} />
                    </Disclosure.Button>
                  </div>
                  <img
                    src={logo}
                    alt='pikkol'
                    className='h-10'
                    onClick={() => {
                      navigate("/");
                    }}
                  />
                  {accessor?.clientId === null && (
                    <div className='text-start hidden lg:block'>
                      <ClientSelector />
                    </div>
                  )}
                </div>
              </div>
              <div className='sm:ml-6 flex items-center justify-between'>
                {accessor && (
                  <Engagespot
                    apiKey={process.env.REACT_APP_ENGAGESPOT_API_KEY}
                    userId={accessor.uid || "no-uid"}
                    renderNotificationIcon={() => {
                      return <NotificationIcon className='h-6 w-6' />;
                    }}
                  />
                )}
                <Menu as='div' className='ml-3 relative'>
                  <div>
                    <Menu.Button className='bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white'>
                      <span className='sr-only'>Open user menu</span>
                      {accessor?.imgUrl ? (
                        <img
                          className='avatar w-11 h-11 rounded-full'
                          src={accessor ? accessor.imgUrl : null}
                          alt='profilePic'
                        />
                      ) : (
                        <span
                          className={`flex flex-col rounded-full  w-11 h-11 ${randomColor} text-white align-middle justify-center text-xl font-bold`}>
                          {accessor?.name &&
                            accessor?.name
                              .split(" ")
                              .filter((_, index) => index < 2)
                              .map(word => word.charAt(0).toUpperCase())
                              .join("")}
                        </span>
                      )}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'>
                    <Menu.Items className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
                      {isDevAdmin && (
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              onClick={navigateToDevAdminPage}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700",
                              )}>
                              Internal Admins
                            </div>
                          )}
                        </Menu.Item>
                      )}
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={signOutUser}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700",
                            )}>
                            Sign out
                          </div>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              {/* Mobile menu button */}
            </div>
          </div>
          <Disclosure.Panel className='lg:hidden'>
            <div className='pt-2 pb-3 space-y-1'>
              <span className=''>
                {accessor?.clientId === null && (
                  <div className='text-start block ml-6'>
                    <ClientSelector />
                  </div>
                )}
              </span>
              <SideNavList />
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
