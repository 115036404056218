import { baseURL, getIdToken } from "./index";

import axios from "axios";

export const getAccessor = async () =>
  axios({
    baseURL,
    url: "/users/accessor",
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
  })
    .then(response => response.data)
    .catch(error => error);

export const getUsers = async (pageSize, pageNumber, clientUid, searchTerm) =>
  axios({
    baseURL,
    url: `/users`,
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
    params: {
      pageSize,
      pageNumber,
      clientUid,
      search: searchTerm,
    },
  })
    .then(response => response.data)
    .catch(error => error);
/**
 *
 * @param {{
 * name: string,
 * email: string,
 * phoneNumber: string,
 * groupUids: string[],
 * tags: string[]
 * }} userData
 * @returns
 */
export const addUser = async userData =>
  axios({
    baseURL,
    url: "/users",
    method: "POST",
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
    data: userData,
  })
    .then(response => response.data)
    .catch(error => error);

export const updateUserName = async (uid, name) =>
  axios({
    baseURL,
    url: `/users/${uid}`,
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
    data: {
      operation: "update_name",
      name,
    },
  }).then(response => response);

export const updateUserEmail = async (uid, email) =>
  axios({
    baseURL,
    url: `/users/${uid}`,
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
    data: {
      operation: "update_email",
      email,
    },
  }).then(response => response);

export const updateUserPhone = async (uid, phoneNumber) =>
  axios({
    baseURL,
    url: `/users/${uid}`,
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
    data: {
      operation: "update_phone",
      phoneNumber,
    },
  }).then(response => response.data);

export const deactivateUser = async uid =>
  axios({
    baseURL,
    url: `/users/${uid}`,
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
    data: {
      operation: "deactivate_user",
    },
  }).then(response => response.data);

export const reactivateUser = async uid =>
  axios({
    baseURL,
    url: `/users/${uid}`,
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
    data: {
      operation: "reactivate_user",
    },
  }).then(response => response.data);
/**
 *
 * @param {string} uid
 * @param {string} membershipUid
 * @returns
 */
export const removeGroupMembership = async (uid, membershipUid) =>
  axios({
    baseURL,
    url: `/users/${uid}`,
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
    data: {
      operation: "remove_group_membership",
      membershipUid,
    },
  }).then(response => response.data);

export const getMentionableUsers = async (search, clientUid, ignoreUserUids) =>
  axios({
    baseURL,
    url: `/users/mentions`,
    params: {
      search,
      clientUid,
      ignoreUserUids,
    },
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
  }).then(response => response.data);

/**
 *
 * @param {string[]} groupUids
 */
export const addGroupsToUser = async (uid, groupUids) =>
  axios({
    baseURL,
    url: `/users/${uid}`,
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
    data: {
      operation: "add_group_memberships",
      groupUids,
    },
  }).then(response => response.data);

/**
 *
 * @param {string} uid
 * @param {string[]} tagNames
 * @returns
 */
export const addTagsToUser = async (uid, tags) =>
  axios({
    baseURL,
    url: `/users/${uid}`,
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
    data: {
      operation: "add_tags",
      tags,
    },
  }).then(response => response.data);

/**
 *
 * @param {string} uid
 * @param {string[]} tagNames
 * @returns
 */
export const removeTagsFromUser = async (uid, tags) =>
  axios({
    baseURL,
    url: `/users/${uid}`,
    method: "PATCH",
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
    data: {
      operation: "remove_tags",
      tags,
    },
  });
