import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ComboBoxAsyncSingle from "../ComboBoxSingleAsync";
import { getClients } from "../../Api";
import { updateClient } from "../../redux/filters/filters.actions";
import useDebounceState from "../../customHooks/useDebounceState";

const hasGroups = (checkGroups, groups) =>
  groups.some(cGroup => checkGroups.some(group => group === cGroup));

export default function ClientSelector({ id, isDisabled }) {
  const { client } = useSelector(state => state.filters);
  const [search, setSearch] = useState("");

  const [clientList, setClientList] = useState([]);
  const dispatch = useDispatch();
  const { accessor } = useSelector(state => state.accessor);

  const getClientNames = async () => {
    if (
      hasGroups(
        accessor.groups.map(group => group.groupName),
        ["Developer Administrators", "Super user"],
      )
    ) {
      const clientNames = await getClients({ search, pageSize: 100 });
      setClientList(clientNames.clients);
    }
  };
  const debouncedSearch = useDebounceState({
    value: search,
    delay: 500,
  });

  useEffect(() => {
    getClientNames();
  }, [debouncedSearch]);

  const clientOptions = clientList.map(c => ({
    value: c.uid,
    label: c.name,
  }));

  return hasGroups(
    accessor.groups.map(group => group.groupName),
    ["Developer Administrators", "Super user"],
  ) ? (
    <ComboBoxAsyncSingle
      id={id || "client-selector"}
      isDisabled={isDisabled}
      placeholder='Select Client'
      onInputChange={e => {
        setSearch(e.target.value);
      }}
      onChange={e => {
        dispatch(updateClient(e));
      }}
      options={clientOptions}
      values={client}
      inputValue={search}
      isClearable={true}
      className='dropdown client-selector w-full'
    />
  ) : (
    <></>
  );
}
