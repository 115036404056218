import { baseURL, getIdToken } from "./index";

// Axios
import axios from "axios";

/**
 *
 * @param {string} waybillNumber
 * @param {number} pageSize
 * @param {number} pageNumber
 * @returns
 */
export const getCommentsOfShipment = async ({
  waybillNumber,
  pageSize,
  pageNumber,
}) => {
  return axios({
    baseURL,
    url: `/shipments/${waybillNumber}/comments`,
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
    params: {
      pageSize,
      pageNumber,
    },
  }).then(response => response.data);
};

export const addComment = async (
  targetObject,
  text,
  isInternal,
  parentUid = null,
) => {
  const { type, uid, waybillNumber } = targetObject;
  const url = `/${type === "shipments" ? "shipments" : "ndr_reports"}/${
    type === "shipments" ? waybillNumber : uid
  }/comments`;

  const data = { text, isInternal, parentUid };

  return axios({
    baseURL,
    url,
    method: "POST",
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
    data,
  }).then(response => response.data);
};

export const getCommentsForTimeline = async ({
  clientUid,
  pageNumber,
  pageSize,
}) => {
  return axios({
    baseURL,
    url: `/comments`,
    method: "GET",
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
    params: {
      clientUid,
      pageNumber,
      pageSize,
    },
  }).then(response => response.data);
};
