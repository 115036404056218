import { combineReducers } from "redux";

import accessorReducer from "./accessor/accessor.reducer";
import filtersReducer from "./filters/filters.reducer";
import promptReducer from "./prompt/prompt.reducer";
import manifestReducer from "./draftManifest/draftManifest.reducer";
import toastMessagesReducer from "./toastMessages/toastMessages.reducer";

const rootReducer = combineReducers({
  accessor: accessorReducer,
  filters: filtersReducer,
  prompt: promptReducer,
  draftManifest: manifestReducer,
  toastMessages: toastMessagesReducer,
});

export default rootReducer;
