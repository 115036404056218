import "./App.css";

import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import React, { Suspense, lazy, useEffect } from "react";
// Firebase Auth
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";

// Components
import HeaderNew from "./components/HeaderNew";
import { ReactComponent as LoadingIcon } from "./assets/Loading.svg";
import SideNav from "./components/SideNav";
import Toasts from "./components/Toasts";
import { getAccessor } from "./Api";
import pages from "./components/pages";
import { setTags } from "./redux/filters/filters.actions";
// Actions
import { updateAccessor } from "./redux/accessor/accessor.actions";

// Pages
const LoginPage = lazy(() => import("./pages/LoginPage"));
const HomePage = lazy(() => import("./pages/HomePage"));
const ErrorPage = lazy(() => import("./pages/ErrorPage"));
const ShipmentPage = lazy(() => import("./pages/ShipmentPage"));
const ShipmentsListPage = lazy(() => import("./pages/ShipmentsListPage"));
const ManifestsListPage = lazy(() => import("./pages/ManifestsListPage"));
const ManifestPage = lazy(() => import("./pages/ManifestPage"));
const TrackingPage = lazy(() => import("./pages/TrackingPage"));
const SearchPage = lazy(() => import("./pages/SearchPage"));
const ComingSoonPage = lazy(() => import("./pages/ComingSoonPage"));
const UploadPage = lazy(() => import("./pages/UploadPage"));
const AnalyticsPage = lazy(() => import("./pages/AnalyticsPage"));
const UnauthorizedPage = lazy(() => import("./pages/UnauthorizedPage"));
const AdministrationPage = lazy(() => import("./pages/AdministrationPage"));
const TermsOfServicePage = lazy(() => import("./pages/TermsOfServicePage"));
const PrivacyPolicyPage = lazy(() => import("./pages/PrivacyPolicyPage"));
const DevAdministrationPage = lazy(() =>
  import("./pages/DevAdministrationPage"),
);
const ServiceRequestsPage = lazy(() => import("./pages/ServiceRequestsPage"));

// Prompt
const ConfirmPrompt = lazy(() => import("./components/ConfirmPrompt"));

// Check if user groups has access to roles defined
const userHasAccess = (path, userGroups) =>
  pages
    .find(page => page.path === path.split("/")[1])
    .groups.some(group => userGroups.some(userGroup => userGroup === group));

function App() {
  const auth = getAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const accessor = useSelector(state => state.accessor.accessor);

  const ProtectedRouteElement = ({ path, component, ...props }) => {
    const location = useLocation();
    const accessor = useSelector(state => state.accessor.accessor);

    const userGroups =
      accessor && accessor.groups.map(group => group.groupName);

    // Wait for groups
    if (!userGroups) return <LoadingIcon />;
    // Authenticated user -> go to route, while waiting for group
    else if (
      accessor &&
      !accessor.deactivatedAt &&
      (path === "/" || userHasAccess(path, userGroups))
    )
      return (
        <>
          <SideNav>{component}</SideNav>
        </>
      );
    else if (accessor && accessor.deactivatedAt) return <ErrorPage />;
    // Unauthenticated user -> go to login path, but Navigate to requested page once logged in
    else if (accessor) {
      console.log("No access");
      return <Navigate to='/' />;
    } else {
      return (
        <Navigate
          to={`/login?redirect=${path.slice(1, 100)}${Object.entries(location)
            .map(([key, value]) => `&${key}=${value}`)
            .join("")}`}
        />
      );
    }
  };

  const listenToAuthStateChanges = async () => {
    onAuthStateChanged(auth, async fbUser => {
      let { redirect, ...propObject } = location.search;
      if (fbUser) {
        // API call to backend to get user details from token getAccessor()
        const { accessor } = await getAccessor();
        // Update redux state
        if (!accessor) {
          console.log(
            "You are not authorized on this application. Please contact the app administrator",
          );
          navigate("/unauthorized");
          return;
        } else {
          dispatch(updateAccessor({ ...accessor, imgUrl: fbUser?.photoURL }));
          dispatch(
            setTags(accessor.tags.map(tag => ({ name: tag, label: tag }))),
          );
        }
        if (window.location.pathname === "/login")
          navigate(
            `/${redirect || ""}${
              Object.entries(propObject).length > 0
                ? `?${Object.entries(propObject)
                    .map(([key, value]) => `${key}=${value}`)
                    .join("&")}`
                : ``
            }`,
          );
      } else if (
        !["/login", "/search", "/terms-of-service", "/privacy-policy"].includes(
          location.pathname,
        ) &&
        !location.pathname.match(/\/shipments\/[0-9]{10}\/track/)
      )
        // if in any other page other than track page Navigate the user to the login page
        navigate("/login");
    });
  };

  useEffect(() => {
    listenToAuthStateChanges();
  }, []);

  const RedirectPath = () => {
    const href = window.location.href;
    const billNumber = href.split("shipment/")?.pop()?.split("/track")?.[0];
    if (billNumber) {
      return `/shipments/${billNumber}/track`;
    }
    return "/";
  };

  return (
    <div>
      {(accessor ||
        location.pathname === "/unauthorized" ||
        location.pathname.match(/\/shipments\/[d]{10}\/track/)) && (
        <HeaderNew />
      )}
      <Toasts />
      <Suspense fallback={<LoadingIcon />}>
        <Routes>
          <Route path='/' element={<Outlet />}>
            <Route
              index
              element={
                <ProtectedRouteElement path='/' component={<HomePage />} />
              }
            />

            <Route path='shipments' element={<Outlet />}>
              <Route
                index
                element={
                  <ProtectedRouteElement
                    path='/shipments'
                    component={<ShipmentsListPage />}
                  />
                }
              />
              <Route
                path='upload'
                element={
                  <ProtectedRouteElement
                    path='/shipments/upload'
                    component={<UploadPage />}
                  />
                }
              />
              <Route path=':waybillNumber' element={<Outlet />}>
                <Route
                  index
                  element={
                    <ProtectedRouteElement
                      path='/shipments/:waybillNumber'
                      component={<ShipmentPage />}
                    />
                  }
                />
                <Route path='track' element={<TrackingPage />} />
              </Route>
            </Route>

            <Route path='shipment' element={<Outlet />}>
              <Route path=':waybillNumber'>
                <Route
                  path='track'
                  index
                  element={<Navigate to={RedirectPath()} replace />}
                />
              </Route>
            </Route>

            <Route path='manifests' element={<Outlet />}>
              <Route
                index
                element={
                  <ProtectedRouteElement
                    path='/manifests'
                    component={<ManifestsListPage />}
                  />
                }
              />
              <Route
                path=':manifestId'
                element={
                  <ProtectedRouteElement
                    path='/manifest/:manifestId'
                    component={<ManifestPage />}
                  />
                }
              />
            </Route>

            <Route
              path='ndr'
              element={
                <ProtectedRouteElement
                  path='/ndr'
                  component={<ComingSoonPage />}
                />
              }
            />
            <Route
              path='cod'
              element={
                <ProtectedRouteElement
                  path='/cod'
                  component={<ComingSoonPage />}
                />
              }
            />
            <Route
              path='reports'
              element={
                <ProtectedRouteElement
                  path='/reports'
                  component={<AnalyticsPage />}
                />
              }
            />
            <Route
              path='dev-admin'
              element={
                <ProtectedRouteElement
                  path='/dev-admin'
                  component={<DevAdministrationPage />}
                />
              }
            />
            <Route
              path='admin'
              element={
                <ProtectedRouteElement
                  path='/admin'
                  component={<AdministrationPage />}
                />
              }
            />
            <Route
              path='service-requests'
              element={
                <ProtectedRouteElement
                  path='/service-requests'
                  component={<ServiceRequestsPage />}
                />
              }
            />
          </Route>
          <Route path='terms-of-service' element={<TermsOfServicePage />} />
          <Route path='privacy-policy' element={<PrivacyPolicyPage />} />
          <Route path='unauthorized' element={<UnauthorizedPage />} />
          <Route path='login' element={<LoginPage />} />
          <Route path='search' element={<SearchPage />} />
          <Route path='*' element={<ErrorPage />} />
        </Routes>
      </Suspense>

      <ConfirmPrompt />
    </div>
  );
}

export default App;
