import { baseURL, getIdToken } from "./index";

// Axios
import axios from "axios";

export const addFileToShipment = async (waybillNumber, file) => {
  let data = new FormData();
  data.append("file", file, file.name);
  return await axios({
    baseURL,
    url: `/shipments/${waybillNumber}/files`,
    method: "POST",
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
      "Content-Type": "multipart/form-data",
    },
    data,
  }).then(response => response.data);
};

export const getFilesOfShipment = async waybillNumber => {
  return axios({
    baseURL,
    url: `/shipments/${waybillNumber}/files`,
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
  }).then(response => response.data);
};

export const getFileByUid = async uid => {
  return axios({
    baseURL,
    url: `/files/${uid}`,
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
  }).then(response => response.data);
};
