import { baseURL, getIdToken } from "./index";

// Axios
import axios from "axios";

export const getAllTags = async ({
  getShipmentsCount,
  search,
  pageSize,
  pageNumber,
}) =>
  axios({
    baseURL,
    url: `/tags`,
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
    params: {
      getShipmentsCount,
      search,
      pageSize,
      pageNumber,
    },
  }).then(response => response.data);

export const addTags = async userData => {
  axios({
    baseURL,
    url: `/tags`,
    method: "POST",
    headers: {
      Authorization: `bearer ${await getIdToken()}`,
    },
    data: userData,
  })
    .then(response => response.data)
    .catch(error => error);
};
