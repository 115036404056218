import React from "react";
import Toast from "../Toast";
import { useSelector } from "react-redux";

const Toasts = props => {
  const toastMessages = useSelector(state => state.toastMessages.toastMessages);
  // Todo Key is a wrong implementation
  return (
    <div className="fixed z-50 bottom-16 left-16 space-y-2">
      {toastMessages?.length > 0 &&
        toastMessages.map((message, index) => (
          <Toast
            message={message.message}
            key={`${message.message}-${index}`}
            type={message.type}
          />
        ))}
    </div>
  );
};

export default Toasts;
