import { Combobox, Transition } from "@headlessui/react";

import { ReactComponent as ChevronDown } from "../../assets/ChevronDown.svg";
import { ReactComponent as CloseIcon } from "../../assets/Close.svg";
import { Fragment } from "react";
import { ReactComponent as Tick } from "../../assets/tick.svg";
import classNames from "classnames";

const ComboBoxAsyncSingle = ({
  values,
  label = "",
  options,
  onChange,
  onInputChange,
  className,
}) => {
  const clearSelection = () => {
    onChange(null);
    onInputChange({ target: { value: "" } });
  };

  return (
    <div className={className}>
      <Combobox as='div' nullable={true} value={values} onChange={onChange}>
        <Combobox.Label className='block text-xs font-medium leading-5 text-gray-700'>
          {label}
        </Combobox.Label>
        <div className='relative mt-1'>
          <div className='flex flex-row items-center w-full cursor-default border overflow-hidden bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-primary-main text-sm rounded'>
            <Combobox.Input
              className={`rounded w-full border-none py-2 pl-3 pr-1 text-sm leading-5 text-gray-900 focus:ring-0 ${
                values?.label ? ` font-bold ` : ``
              } `}
              onChange={onInputChange}
              displayValue={values => values?.label || ""}
            />
            <div
              className={`px-2 ${values ? `visible` : `hidden`}`}
              onClick={clearSelection}>
              <CloseIcon />
            </div>

            <Combobox.Button className=' inset-y-0 right-0 flex items-center pr-2'>
              <ChevronDown className='bg-primary-lightest h-7 w-7 text-primary-darkest fill-current br-8 rounded' />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <Combobox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
              {options.length === 0 && options !== "" ? (
                <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                  Nothing found.
                </div>
              ) : (
                options.map(option => {
                  return (
                    <Combobox.Option
                      key={option.value}
                      className={({ selected, active }) =>
                        `relative cursor-default select-none py-2 pl-3 pr-4 text-gray-900 ${
                          active ? "bg-primary-lightest" : ""
                        } ${selected ? " font-medium " : ""}`
                      }
                      value={option}>
                      {({ selected, active }) => (
                        <>
                          <div className='flex flex-row items-center'>
                            <Tick
                              className={`${
                                selected ? `bg-primary-main` : ` bg-white `
                              } fill-white h-4 w-4 rounded border border-grey-300 mr-3`}
                            />
                            <span
                              className={`${
                                selected ? "font-semibold" : "font-normal"
                              } block truncate`}>
                              {option.label}
                            </span>
                          </div>
                          {selected && (
                            <>
                              <span
                                className={classNames(
                                  "absolute inset-y-0 right-0 flex items-center pr-4 ",
                                  active ? "text-white" : "text-indigo-600",
                                )}>
                                t
                              </span>
                            </>
                          )}
                        </>
                      )}
                    </Combobox.Option>
                  );
                })
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};

export default ComboBoxAsyncSingle;
