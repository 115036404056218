export const FilterTypes = {
  UPDATE_CLIENT: "UPDATE_CLIENT",
  UPDATE_SHIPMENTS_PAGINATION: "UPDATE_SHIPMENTS_PAGINATION",
  UPDATE_MANIFESTS_PAGINATION: "UPDATE_MANIFESTS_PAGINATION",
  UPDATE_ADMINISTRATION_PAGINATION: "UPDATE_ADMINISTRATION_PAGINATION",
  UPDATE_TAGS_PAGINATION: "UPDATE_TAGS_PAGINATION",
  UPDATE_CLIENTS_PAGINATION: "UPDATE_CLIENTS_PAGINATION",
  UPDATE_SERVICE_REQUESTS_PAGINATION: "UPDATE_SERVICE_REQUESTS_PAGINATION",
  UPDATE_SHIPMENTS_CURRENT_TAB: "UPDATE_SHIPMENTS_CURRENT_TAB",
  SET_WAYBILL_TERM: "SET_WAYBILL_TERM",
  SET_SHIPMENT_STATUSES: "SET_SHIPMENT_STATUSES",
  SET_SHIPMENT_TYPES: "SET_SHIPMENT_TYPES",
  SET_PICKUP_DATE: "SET_PICKUP_DATE",
  SET_DELIVERY_DATE: "SET_DELIVERY_DATE",
  SET_CREATED_DATE: "SET_CREATED_DATE",
  SET_USER_TERM: "SET_USER_TERM",
  SET_ACTUAL_DELIVERY_DATE: "SET_ACTUAL_DELIVERY_DATE",
  SET_ACTUAL_PICKUP_DATE: "SET_ACTUAL_PICKUP_DATE",
  SET_SCHEDULED_ATTEMPT_DATE: "SET_SCHEDULED_ATTEMPT_DATE",
  SET_EXPECTED_ATTEMPT_DATE: "SET_EXPECTED_ATTEMPT_DATE",
  SET_TAGS: "SET_TAGS",
};
