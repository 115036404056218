import { ReactComponent as AdminIcon } from "../../assets/Admin.svg";
import { ReactComponent as CODIcon } from "../../assets/COD.svg";
import { ReactComponent as DashboardIcon } from "../../assets/Dashboard.svg";
import { ReactComponent as ManifestsIcon } from "../../assets/Manifests.svg";
import { ReactComponent as NDRIcon } from "../../assets/NDR.svg";
import { NavLink } from "react-router-dom";
import React from "react";
import { ReactComponent as ReportsIcon } from "../../assets/Reports.svg";
import { ReactComponent as ShipmentsIcon } from "../../assets/Shipments.svg";
import { useSelector } from "react-redux";

const SideNavList = props => {
  const accessor = useSelector(state => state.accessor.accessor);
  const options = [
    {
      title: "Dashboard",
      endpoint: "/",
      icon: (
        <DashboardIcon className='fill-current text-grey-900 w-4 h-4 mr-2' />
      ),
    },
    {
      title: "Shipments",
      endpoint: "/shipments",
      icon: (
        <ShipmentsIcon className='fill-current text-grey-900 w-4 h-4 mr-2' />
      ),
    },
    {
      title: "Manifests",
      endpoint: "/manifests",
      icon: (
        <ManifestsIcon className='fill-current text-grey-900 w-4 h-4 mr-2' />
      ),
    },
    {
      title: "Service requests",
      endpoint: "/service-requests",
      icon: <NDRIcon className='fill-current text-grey-900 w-4 h-4 mr-2' />,
    },
    {
      title: "NDRs",
      endpoint: "/ndr",
      icon: <NDRIcon className='fill-current text-grey-900 w-4 h-4 mr-2' />,
    },
    {
      title: "COD Remittance",
      endpoint: "/cod",
      icon: <CODIcon className='fill-current text-grey-900 w-4 h-4 mr-2' />,
    },
    {
      title: "Reports",
      endpoint: "/reports",
      icon: <ReportsIcon className='fill-current text-grey-900 w-4 h-4 mr-2' />,
    },
    {
      title: "Administration",
      endpoint: "/admin",
      icon: <AdminIcon className='fill-current text-grey-900 w-4 h-4 mr-2' />,
      enabled:
        (accessor.clientId === null &&
          (accessor.groups
            .map(group => group.groupName)
            .includes("Developer Administrators") ||
            accessor.groups
              .map(group => group.groupName)
              .includes("Super user"))) ||
        (accessor.clientId !== null &&
          accessor.groups.map(group => group.groupName).includes("admin")),
    },
  ].map(
    ({ title, icon, endpoint, enabled = true }) =>
      enabled && (
        <NavLink
          key={endpoint}
          className={({ isActive }) =>
            `text-grey-300 hover:text-grey-900 hover:font-bold w-full no-underline py-2 text-xs font-medium flex flex-row items-center justify-start
        ${isActive ? "font-bold active-navlink stroke-2" : ""}`
          }
          to={endpoint}>
          {icon}
          {title}
        </NavLink>
      ),
  );

  return (
    <div className='mt-3 sm:pl-6 pl-6 lg:pl-8 2xl:pl-8 flex flex-col space-x-0 list-none '>
      {options}
    </div>
  );
};

export default SideNavList;
